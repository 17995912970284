<template>
  <!-- <div>
   
    <div>
      <div class="row">
        <div class="col-6">
        <v-card style=" border-radius:30px;">
          <v-app>
                  <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                    <div class="col-xl-12 col-xxl-12">
                      <v-form ref="form" v-model="valid" v-show="true" lazy-validation>
                        <div
                          class="pb-5"
                          data-wizard-type="step-content"
                          data-wizard-state="current"
                        >
                        <div class="row">
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="pb-0 mb-0"
                          >
                            <v-text-field
                              v-model="formItem.email"
                              id="email"
                              label="Email"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="pb-0 mb-0"
                          >
                            <v-text-field
                              v-model="formItem.no_hp"
                              id="no_hp"
                              label="No. Hp"
                              readonly
                            ></v-text-field>
                          </v-col>
                           <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="pb-0 mb-0"
                          >
                            <v-text-field
                              v-model="formItem.url"
                              id="url"
                              label="Url"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="pb-0 mb-0"
                          >
                            <v-text-field
                              v-model="formItem.limit_users"
                              id="limit_users"
                              label="Limit Akun"
                              readonly
                            ></v-text-field>
                          </v-col>
                           <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="pb-0 mb-0"
                          >
                            <v-text-field
                              v-model="formItem.address"
                              id="address"
                              label="Alamat"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </div>
                        </div>
                      </v-form>
                    </div>
                  </div>
              
          </v-app>
        </v-card>
        </div>
         <div class="col-6">
         <v-card style=" border-radius:30px;">
          <v-app>
                <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                  <div class="col-xl-12 col-xxl-12">
                    <v-form ref="form" v-model="valid" v-show="true" lazy-validation>
                      <div
                        class="pb-5"
                        data-wizard-type="step-content"
                        data-wizard-state="current"
                      >
                      <div class="row">
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          class="pb-0 mb-0 text-center"
                        >
                          <div class="form-group">
                                <div class="image-input image-input-outline" id="kt_profile_avatar">
                                  <div
                                      class="image-input-wrapper"
                                      :style="{ backgroundImage: `url(${current_photo})` }"
                                      style="width: 200px; height: 200px;"
                                  ></div>
                              </div>
                               <span class="form-text" style="font-size: 16px; font-family: Poppins, Helvetica, 'sans-serif';">{{formItem.name}}</span>
                          </div>
                        </v-col>
                      </div>
                      </div>
                    </v-form>
                  </div>
            </div>
          </v-app>
         </v-card>
        </div>
     </div>
    </div>
    </div> -->
     <div class="d-flex flex-row">
    <div
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      id="kt_profile_aside"
    >
      <div class="card card-custom card-stretch">
        <div class="card-body pt-4">
          <div class="d-flex justify-content-end">
            <!-- <KTDropdown2></KTDropdown2> -->
          </div>
          <div class="d-flex align-items-center">
            <div
              class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
            >
              <div
                class="symbol-label"
                :style="{
                  backgroundImage: `url(${current_photo})`
                }"
              ></div>
              <i class="symbol-badge bg-success"></i>
            </div>
            <div>
              <a
                class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                >{{ formItem.name }}</a
              >
              <div class="text-muted"></div>
            </div>
          </div>
          <!--end::User-->
          <!--begin::Contact-->
          <div class="py-9">
            <div class="d-flex align-items-center justify-content-between mb-5">
              <span class="font-weight-bold mr-2">Email:</span>
              <a class="text-muted text-hover-primary">{{
                formItem.email
              }}</a>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-5">
              <span class="font-weight-bold mr-2">No. Hp:</span>
              <span class="text-muted">{{
                formItem.no_hp
              }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-5">
              <span class="font-weight-bold mr-2">Url Perusahaan:</span>
              <span class="text-muted">{{
                formItem.url
              }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-5">
              <span class="font-weight-bold mr-2">Batas Pengguna:</span>
              <span class="label label-lg label-inline py-4 label-light-warning">{{
                formItem.limit_users_conv + ' Devices'
              }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-5">
              <span class="font-weight-bold mr-2">Alamat:</span>
              <span class="text-muted">{{
                formItem.address
              }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-5">
              <span class="font-weight-bold mr-2">Tanggal Bergabung:</span>
              <span class="label label-lg label-inline py-4 label-light-success">{{
                formItem.join_date_conv
              }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-5">
              <span class="font-weight-bold mr-2">Masa Berlaku Sampai:</span>
              <span class="label label-lg label-inline py-4 label-light-danger">{{
                formItem.exp_activation_date_conv
              }}</span>
            </div>
            <!-- <div class="d-flex align-items-center justify-content-between mb-5">
              <span class="font-weight-bold mr-2">Jangka Berlangganan:</span>
              <span class="label label-lg label-inline py-4 label-light-info">{{
                formItem.range_activation_day_conv + ' days'
              }}</span>
            </div> -->
          </div>
          <!--end::Contact-->
          <!--begin::Nav-->
          <div
            class="navi navi-bold navi-hover navi-active navi-link-rounded"
            role="tablist"
          >
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4 active"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="0"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Files/File.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Informasi Subscribe</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
     <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs class="hide-tabs" v-model="tabIndex">
        <b-tab active>
          <div>
            <div class="row">
              <div class="col-lg-6">
                 <div class="card card-custom gutter-b card-stretch">
                  <!--begin::Header-->
                  <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Invoice</h3>
                    <div class="card-toolbar">
                      <div class="dropdown dropdown-inline">
                        <!-- <Dropdown2></Dropdown2> -->
                      </div>
                    </div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Body-->
                  <div class="card-body pt-2">
                    <div v-if="dInvoice.length == 0" class="col text-center">
                      <span>
                        <img src="media/logos/no_invoice.png" style="width:300px; height:300px;" class="mt-10"/>
                      </span>
                    </div>
                    <div v-if="dInvoice.length == 0" class="text-center">
                      <span class="text-muted font-weight-bold">Belum Ada Invoice Saat Ini</span>
                    </div>
                    <div v-if="dInvoice.length > 0">
                    <template v-for="(item, i) in dInvoice">
                      <!--begin::Item-->
                      <div
                        class="d-flex flex-wrap align-items-center"
                        v-bind:key="i"
                      >
                        <!--begin::Symbol-->
                        <!-- <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                          <div
                            class="symbol-label"
                            :style="`background-image:url(${item.text0})`"
                          ></div>
                        </div> -->
                        <!--end::Symbol-->
                        <!--begin::Title-->
                        <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3 pb-3">
                          <span class="text-dark-75 font-weight-bolder font-size-lg">
                          Bukti Bayar
                          </span>
                            <!-- <a
                              href="#"
                              @click="getPayment(item.id, item.description)"
                              class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                              >Bukti Bayar</a
                            > -->
                          <span class="text-muted font-weight-bold font-size-sm my-1">{{
                            item.invoice_date_conv
                          }}</span>
                          <span class="text-muted font-weight-bold font-size-sm"
                            >Deskripsi:
                            <a
                              href="#"
                              @click="getPayment(item.id, item.description)"
                              class="text-dark-75 font-weight-bolder text-hover-primary font-size-sm"
                              >{{item.description}}</a
                          ></span>
                        </div>
                        <!--end::Title-->
                        <!--begin::Info-->
                        <div class="d-flex align-items-center py-lg-0 py-2">
                          <div class="d-flex flex-column text-right">
                            <span class="text-muted font-size-sm font-weight-bolder"
                              >Rp. </span
                            >
                            <span class="text-dark-75 font-weight-bolder font-size-h4">{{
                              item.amount_conv
                            }}</span>
                           
                          </div>
                        </div>
                        <!--end::Info-->
                      </div>
                      <!--end::Item-->
                    </template>
                    </div>
                    <div v-if="dInvoice.length > 0" class="row col-lg-12 mt-2">
                      <v-col cols="6" md="6" sm="6" class="text-right">
                        <v-btn :disabled="isDisabledB" ref="back" medium elevation="" style="background-color: #f2f2c1;" @click="backpage()"
                          >Back</v-btn
                        >
                      </v-col>
                      <v-col cols="6" md="6" sm="6" class="text-left">
                        <v-btn :disabled="isDisabledN" ref="next" medium elevation="" style="background-color: #f2f2c1;" @click="nextPage()"
                          >Next</v-btn
                        >
                      </v-col>
                    </div>
                  </div>
                  <!--end::Body-->
                </div>
              </div>
              <div class="col-lg-6">
                <div class="card card-custom card-stretch gutter-b">
                  <!--begin::Header-->
                  <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Payment {{deskripsiPayment}}</h3>
                    <div class="card-toolbar">
                      <!-- <Dropdown2></Dropdown2> -->
                    </div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Body-->
                  <div class="card-body pt-0">
                    <div v-if="dPayment.length == 0" class="col text-center">
                      <span>
                        <img src="media/logos/no_payment.png" style="width:300px; height:300px;" class="mt-10"/>
                      </span>
                    </div>
                    <div v-if="dPayment.length == 0" class="text-center">
                      <span class="text-muted font-weight-bold">Klik Invoice untuk melihat detail Payment</span>
                    </div>
                    <div v-if="dPayment.length > 0">
                    <template v-for="(item, i) in paymentItem">
                      <div class="mb-6" v-bind:key="i">
                        <!--begin::Content-->
                        <div class="d-flex align-items-center flex-grow-1">
                          <!--begin::Checkbox-->
                          <!-- <label
                            class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4"
                          >
                            <input type="checkbox" value="1" />
                            <span></span>
                          </label> -->
                          <!--end::Checkbox-->
                          <!--begin::Section-->
                          <div
                            class="d-flex flex-wrap align-items-center justify-content-between w-100"
                          >
                            <!--begin::Info-->
                            <div class="d-flex flex-column align-items-cente py-2 w-75">
                              <!--begin::Title-->
                              <a
                                href="#"
                                class="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                                >Pembayaran</a
                              >
                              <!--end::Title-->
                              <!--begin::Data-->
                              <span class="text-muted font-weight-bold">{{ item.description }}</span>
                              <a
                                href="#"
                                class="text-muted font-weight-bold"
                                >{{item.payment_date_conv}}</a
                              >
                              <!--end::Title-->
                              <!--begin::Data-->
                              <span class="text-muted font-weight-bold">Metode Pembayaran {{ item.payment_method_conv }}</span>
                              <!--end::Data-->
                              <!--end::Data-->
                            </div>
                            <!--end::Info-->
                            <!--begin::Label-->
                            <div class="d-flex align-items-center py-lg-0 py-2">
                              <div class="d-flex flex-column text-right">
                                <span class="text-muted font-size-sm font-weight-bolder"
                                  >Rp. </span
                                >
                                <span class="text-dark-75 font-weight-bolder font-size-h4">{{
                                  item.amount_conv
                                }}</span>
                              
                              </div>
                            </div>
                            <!--end::Label-->
                          </div>
                          <!--end::Section-->
                        </div>
                        <!--end::Content-->
                      </div>
                    </template>
                    </div>
                    <div v-if="dPayment.length > 0" class="row col-lg-12 mt-2">
                      <v-col cols="6" md="6" sm="6" class="text-right">
                        <v-btn :disabled="isDisabledBPay" ref="back" medium elevation="" style="background-color: #f2f2c1;" @click="backpagePay()"
                          >Back</v-btn
                        >
                      </v-col>
                      <v-col cols="6" md="6" sm="6" class="text-left">
                        <v-btn :disabled="isDisabledNPay" ref="next" medium elevation="" style="background-color: #f2f2c1;" @click="nextPagePay()"
                          >Next</v-btn
                        >
                      </v-col>
                    </div>
                    <!--begin::Item-->
                    <!--end::Item-->
                  </div>
                  <!--end: Card Body-->
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <!--end::Content-->
     </div>
</template>

<style lang="css">
    .v-application--wrap{ min-height: 0px; }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        background-color: #ffa69e;
        font-size: 13px;
        vertical-align: inherit;
        margin-top: 5%;
    }
</style>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<script>
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import KTUtil from "@/assets/js/components/util";
// import KTWizard from "@/assets/js/components/wizard";
// import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import Services from "@/core/services/notasis-api/Services";
import storagelocal from "@/core/services/storagelocal.service";
// import KTProfileOverview from "@/view/pages/profile/profile-comp-1/ProfileOverview";
// var CryptoJS = require("crypto-js");

export default {
  name: "Wizard-4",
   components: {
    // KTProfileOverview,
  },
  data(){
    return{
      valid: true,
      // ableWizard: true,
      formItem: {
        address: '',
        email: '',
        exp_activation_date: '',
        grace_period_date: '',
        is_activated: 0,
        join_date: '',
        limit_users: 0,
        name: '',
        no_hp: '',
        range_activation_day: 0,
        url: '',
        level: 0
      },
      invoiceItem: {
        id: '',
        amount: 0,
        description: '',
        end_activation_date: '',
        invoice_date: '',
        url: '',
        invoice_exp: '',
        amount_already_paid: 0
      },
      paymentItem: {
        id: '',
        amount: 0,
        description: '',
        payment_date: '',
        payment_method: '',
        payment_path_file: '',
        payment_reference: '',
      },
      idData: '',
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false
      },
      optionsPay: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false
      },
      dInfoPerusahaan: [],
      tdInfoPerusahaan: 0,
      isFirstLoad: true,
      default_photo: "media/users/blank.png",
      current_photo: null,
      profile_photo: null,
      baseUrlUpload: ApiService.getBaseUrlUpload(),
      tabIndex: 0,
      dInvoice: [],
      isDisabledN: false,
      isDisabledB: false,
      dPayment: [],
      isDisabledNPay: false,
      isDisabledBPay: false,
      deskripsiPayment: ''
    }
  },
  mounted() {
    this.load2()
  },
  watch: {
    options: {
        async handler (val) {
          val
            // if(!this.isFirstLoad) 
            //   await this.load2()
        },
        deep: true,
    },
  },
  methods: {
    // lastElement(i) {
    //   if (i === this.list.length - 1) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // },
     /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    nextPage() {
      this.options.page++
      this.getInvoice()
    },
    backpage() {
      this.options.page--
      this.getInvoice()
    },
    checkPage() {
      if (this.options.itemsPerPage * this.options.page == this.dInvoice.length) {
        this.isDisabledN = false
        this.isDisabledB = true
      } else if(this.dInvoice.length < this.options.itemsPerPage && this.options.page > 1) {
        this.isDisabledN = true
        this.isDisabledB = false
      }else if(this.dInvoice.length < this.options.itemsPerPage && this.options.page == 1) {
        this.isDisabledN = true
        this.isDisabledB = true
      } else {
        this.isDisabledN = false
        this.isDisabledB = false
      }
    },
    nextPagePay() {
      this.options.page++
      this.getInvoice()
    },
    backpagePay() {
      this.options.page--
      this.getInvoice()
    },
    checkPagePay() {
      if (this.optionsPay.itemsPerPage * this.optionsPay.page == this.dPayment.length) {
        this.isDisabledNPay = false
        this.isDisabledBPay = true
      } else if(this.dPayment.length < this.optionsPay.itemsPerPage && this.optionsPay.page > 1) {
        this.isDisabledNPay = true
        this.isDisabledBPay = false
      }else if(this.dPayment.length < this.optionsPay.itemsPerPage && this.optionsPay.page == 1) {
        this.isDisabledNPay = true
        this.isDisabledBPay = true
      } else {
        this.isDisabledNPay = false
        this.isDisabledBPay = false
      }
    },
    converterDate(date){
      var convDate = new Date(date)
      var dd = String(convDate.getDate()).padStart(2, '0');
      var mm = String(convDate.getMonth() + 1).padStart(2, '0'); 
      var yyyy = convDate.getFullYear();
  
      return dd + '-' + mm + "-" + yyyy
    },
    converterDateInv(date){
      var convDate = new Date(date)
      const monthNames = ["January", "Februari", "Maret", "April", "Mei", "Juni",
        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
      ];

      var dd = String(convDate.getDate()).padStart(2, '0');
      var mm = String(monthNames[convDate.getMonth()]) 
      var yyyy = convDate.getFullYear();
  
      return dd + ' ' + mm + " " + yyyy
    },
    getInfoPerusahaan(){
      this.dInfoPerusahaan = ""
      return new Promise(resolve => {
        var mydata = {
          UID 		: storagelocal.getLocalStorage('UID'),
          Token		: storagelocal.getLocalStorage('token'),
          Trigger	: 'R',
          Route   : 'get_profile_from_secondary',
          // options : this.options,
          url     : "www.notasis.com",
        }

          Services.PostData2(ApiService, "profile", mydata, async response=>{
            var responseCache = response.data[0]
            this.dInfoPerusahaan = responseCache
            if(responseCache.exp_activation_date != null){
              responseCache.exp_activation_date_conv = this.converterDate(responseCache.exp_activation_date)
            }
            if(responseCache.join_date != null){
              responseCache.join_date_conv = this.converterDate(responseCache.join_date)
            }
            if(responseCache.is_activated == 0){
              responseCache.is_activated_conv = 'Aktif'
            }else{
              responseCache.is_activated_conv = 'Tidak Aktif'
            }
            if(responseCache.path_file != null){
              this.current_photo = await this.getPhotoProfile(responseCache.path_file, this.baseUrlUpload)
            }else{
              this.current_photo = this.default_photo
            }
            if(responseCache.level == 0){
              responseCache.level_conv = 'Admin'
            }else{
              responseCache.level_conv = 'Client'
            }
            if(responseCache.range_activation_day == -1){
              responseCache.range_activation_day_conv = 'Unlimited'
            }else{
              responseCache.range_activation_day_conv = responseCache.range_activation_day
            }
            if(responseCache.limit_users == -1){
              responseCache.limit_users_conv = "Unlimited"
            }else{
              responseCache.limit_users_conv = responseCache.limit_users
            }
            this.formItem = Object.assign({}, responseCache)
            resolve(this.dInfoPerusahaan);
          }, err =>{
            err
          })
      });
    },
    getInvoice(){
      this.dInvoice = ""
      return new Promise(resolve => {
        var mydata = {
          UID 		: storagelocal.getLocalStorage('UID'),
          Token		: storagelocal.getLocalStorage('token'),
          Trigger	: 'R',
          Route   : 'get_inv_from_secondary',
          options : this.options,
          url     : "www.notasis.com",
        }

          Services.PostData2(ApiService, "invoice", mydata, async response=>{
            var responseCache = response.data
            for (let i = 0; i < responseCache.length; i++) {
              if(responseCache[i].invoice_date != null){
                responseCache[i].invoice_date_conv = this.converterDateInv(responseCache[i].invoice_date)
              }
              if(responseCache[i].amount != null){
                responseCache[i].amount_conv = (responseCache[i].amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
              }
            }
            this.dInvoice = responseCache
            this.invoiceItem = Object.assign({}, responseCache)
            this.checkPage()
            resolve(this.dInvoice);
          }, err =>{
            err
          })
      });
    },
    getPayment(id, deskripsi){
      this.dPayment = ""
      this.deskripsiPayment = deskripsi
      return new Promise(resolve => {
        var mydata = {
          UID 		   : storagelocal.getLocalStorage('UID'),
          Token		   : storagelocal.getLocalStorage('token'),
          Trigger	   : 'R',
          Route      : 'get_pay_from_secondary',
          options    : this.optionsPay,
          invoice_id : id,
        }

          Services.PostData2(ApiService, "invoice_payment", mydata, async response=>{
            var responseCache = response.data
            for (let i = 0; i < responseCache.length; i++) {
              if(responseCache[i].payment_date != null){
                responseCache[i].payment_date_conv = this.converterDateInv(responseCache[i].payment_date)
              }
              if(responseCache[i].amount != null){
                responseCache[i].amount_conv = (responseCache[i].amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
              }
              if(responseCache[i].payment_method == 1){
                responseCache[i].payment_method_conv = "Virtual Account"
              }else if(responseCache[i].payment_method == 2){
                responseCache[i].payment_method_conv = "Transfer Bank"
              }else if(responseCache[i].payment_method == 3){
                responseCache[i].payment_method_conv = "Gopay"
              }
            }
            this.dPayment = responseCache
            this.paymentItem = Object.assign({}, responseCache)
            this.checkPagePay()
            resolve(this.dPayment);
          }, err =>{
            err
          })
      });
    },
    async load2(){
      Promise.all([
                    await this.getInfoPerusahaan(),
                    await this.getInvoice()
                  ])
      .then( (results)=> {
        this.isFirstLoad = false
        results
      })
    },
    onFileChange(e) {
        var files = ''

        if(!e)
        return

        files = e
        this.isRemoveImg = 1
        this.createImage(files);
    },
    createImage(files) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;
      image
      reader.onload = (e) => {
        vm.current_photo = e.target.result;
      };
      if (files) {
          reader.readAsDataURL(files.target.files[0]);
      }
      this.profile_photo = files.target.files[0]
    },
    removeImage: function (e) {
      e
      this.current_photo = this.default_photo;
      this.profile_photo = '';
      this.isRemoveImg = 1
    },
    getBlobFromUrl(myImageUrl) {
        return new Promise((resolve, reject) => {
            let request = new XMLHttpRequest();
            request.open('GET', myImageUrl, true);
            request.responseType = 'blob';
            request.onload = () => {
                resolve(request.response);
            };
            request.onerror = reject;
            request.send();
        })
    },
    async getPhotoProfile(item, baseUrlUpload){
        let myBlob = ''
        if(item != null) {
          myBlob = await this.getBlobFromUrl(baseUrlUpload + '/' + item);
        }
        return this.createImageProfile(myBlob)
    },
    createImageProfile(files) {
      if (files) {
        var reader = new FileReader();
        reader.readAsDataURL(files);
        // this.profile_photo = convphoto
        return new Promise(resolve => {
          reader.onload = (e) => {
              resolve( e.target.result)
          };
        })
      }
    },
  }
};
</script>
